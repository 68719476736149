<template>
  <div>
    <div class="mb-8">
      <b-button
        v-if="workspaceId"
        class="mt-2"
        variant="success"
        :href="`https://docs.google.com/spreadsheets/d/${workspaceId}`"
        target="_blank"
      >
        Access CRM Spreadsheet
      </b-button>
      <b-button
        v-if="workspaceId"
        class="mt-2"
        variant=""
        href="/settings?activeTab=danger-zone"
      >
        Having Problems?
      </b-button>
    </div>

    <note
      v-if="paymentIntentError"
      class="p-2"
      :info="{
        message: 'The payment of your last invoice did not succeed, please update your payment method.',
        href:'/billing',
        hrefMessage:'Check billing.'
      }"
    />

    <note
      v-if="!paymentIntentError && cardWillExpire"
      :info="{
        message: 'Your credit card will expire in less then 3 months!',
        href:'/billing',
        hrefMessage:'Check billing.'
      }"
    />

    <note
      v-if="!account.isSubscribed && account.settings.assumeEveryoneIsAdminWhenNoSubscription"
      variant="info"
      :info="{
        message: 'All members of the team currently have admin status. Admins can pay and configure settings for the team. Once one member pays, they become admin and others become normal users. For more information please contact our',
        href:'https://intercom.help/surfe/en/',
        hrefMessage:'Customer Support'
      }"
    />

    <invite-member class="mb-8" />

    <b-row>
      <b-col
        lg="8"
        sm="12"
        class="mb-4"
      >
        <user-list />

        <owners-list />
      </b-col>

      <b-col
        lg="4"
        sm="12"
      >
        <action-feed />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'
import InviteMember from './components/InviteMember.vue'
import Note from './components/Note.vue'
import UserList from './components/UserList.vue'
import OwnersList from './components/OwnersList.vue'
import ActionFeed from './components/ActionFeed.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    InviteMember,
    Note,
    UserList,
    OwnersList,
    ActionFeed,
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      paymentIntentError() {
        return this.$store.getters['api/getPaymentIntentError']
      },
      cardWillExpire: state => {
        const card = state.api.paymentMethods[0]?.card
        if (!card) return false

        // Calculate card and current date, starting from the first day in the month.
        const date = new Date()
        const currentDate = new Date(date.getFullYear(), date.getMonth(), 1)
        const cardDate = new Date(`${card?.exp_year}-${card?.exp_month}-1`)

        // Calculate difference in months between two dates.
        let months = (cardDate.getFullYear() - currentDate.getFullYear()) * 12
        months -= currentDate.getMonth()
        months += cardDate.getMonth()
        return months < 3 && months >= 0
      },
      workspaceId: state => state.api.account.workspaceId,
    }),
  },
}
</script>
