<template>
  <b-card
    no-body
    class="mb-0"
    style="max-height: 80vh"
  >
    <b-table
      class="position-relative mb-0"
      :items="crmOwners"
      :fields="tableColumns"
      :busy="isBusy"
      primary-key="id"
      responsive
      show-empty
      empty-text="No matching records found"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner
            class="align-middle"
            variant="primary"
          />
        </div>
      </template>

      <!-- Column: User -->
      <template #cell(user)="data">
        <b-media style="align-items:center">
          <template #aside>
            <b-avatar
              size="32"
              :text="abbrev(data.item.name)"
            />
          </template>
          <b>{{ data.item.name }}</b>
        </b-media>
      </template>

      <!-- Column: Email -->
      <template #cell(email)="data">
        <span>{{ data.item.email }}</span>
      </template>

      <!-- Column: Invite button -->
      <template #cell()="data">
        <b-button
          class="pl-0"
          size="sm"
          variant="link"
          @click="sendInvite(data.item)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          Invite
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BTable,
  BMedia,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BCard,
    BAvatar,
    BTable,
    BButton,
    BMedia,
    BSpinner,
  },
  data() {
    return {
      tableColumns: [
        { key: 'user', label: 'CRM User' },
        { key: 'email', label: 'Email' },
        { key: '' },
      ],
    }
  },
  computed: {
    ...mapState({
      crmOwners: ({ api: { crmOwners, users } }) => {
        if (Array.isArray(crmOwners) && crmOwners.length > 0) {
          return crmOwners.filter(owner => !users.find(({ email }) => (email === owner.email)))
        }
      },
      isBusy: state => !Array.isArray(state.api.crmOwners),
    }),
  },
  methods: {
    async sendInvite(user) {
      try {
        await this.$store.dispatch('api/inviteUsers', [{
          email: user.email,
          name: user.name,
          isAdmin: false,
        }])
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation sent successfully',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-team-invited', props: { direct: true } })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
    abbrev(name) {
      return name.split(' ').map(n => n[0]).join('')
    },
  },
}
</script>
