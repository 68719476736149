<template>
  <b-card
    v-if="actionFeed.length"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Latest actions</b-card-title>

      <b-button
        class="float-right p-0"
        variant="secondary"
        @click="refreshAction"
      >
        <span v-if="loading">
          <b-spinner
            class="mx-2"
            small
          />
        </span>
        <span v-else>
          <feather-icon
            size="15"
            icon="RefreshCcwIcon"
          />

          Refresh
        </span>
      </b-button>
    </b-card-header>

    <b-card-body>
      <div
        v-for="action, i in actionFeed"
        :key="i"
        class="transaction-item"
        :class="i != actionFeed.length-1 ? 'mb-2': ''"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="30"
              :variant="action.color"
            >
              <feather-icon
                size="15"
                :icon="action.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title mb-0">
              {{ action.type }} <small
                v-if="action.count > 1"
                class="text-bold text-primary text-accent-2"
              >x{{ action.count }}</small>
            </h6>
            <small
              v-if="action.owner"
              class="text-normal text-secondary text-darken-3"
            >by {{ action.owner }}</small>
          </b-media-body>
        </b-media>
        <div class="text-secondary text-darken-2 font-small-2">
          {{ moment(action.date).fromNow() }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BSpinner,
  BButton, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { sleep } from '@/utils'

const actionToTitle = {
  VIEW_LEAD: { text: 'View a CRM contact', avatar: 'UserIcon', color: 'light-info' },
  ADD_LEAD: { text: 'Add a contact', avatar: 'UserIcon', color: 'light-success' },
  ENRICH_LEAD: { text: 'Enrich a contact', avatar: 'UserIcon', color: 'light-warning' },
  UPDATE_LEAD: { text: 'Update a contact', avatar: 'UserIcon', color: 'light-warning' },
  GO_TO_CRM_PROFILE: { text: 'Go to CRM profile', avatar: 'LinkIcon', color: 'light-info' },
  SYNC_CONV: { text: 'Sync conversation', avatar: 'RefreshCwIcon', color: 'light-primary' },
  VIEW_COMPANY: { text: 'View a CRM company', avatar: 'BriefcaseIcon', color: 'light-info' },
  ADD_COMPANY: { text: 'Add a CRM company', avatar: 'BriefcaseIcon', color: 'light-success' },
  SET_COMPANY: { text: 'Set a contact\'s company', avatar: 'BriefcaseIcon', color: 'light-warning' },
  UPDATE_COMPANY: { text: 'Update a company', avatar: 'BriefcaseIcon', color: 'light-warning' },
  ADD_DEAL: { text: 'Add a deal', avatar: 'DollarSignIcon', color: 'light-success' },
  UPDATE_DEAL: { text: 'Update a deal', avatar: 'DollarSignIcon', color: 'light-warning' },
  ADD_NOTES: { text: 'Add a note', avatar: 'FileTextIcon', color: 'light-success' },
  UPDATE_NOTES: { text: 'Update a note', avatar: 'FileTextIcon', color: 'light-warning' },
  DELETE_NOTES: { text: 'Delete a contact', avatar: 'FileTextIcon', color: 'light-danger' },
  ADD_TEMPLATE: { text: 'Add a template', avatar: 'AlignLeftIcon', color: 'light-success' },
  EDIT_TEMPLATE: { text: 'Edit a template', avatar: 'AlignLeftIcon', color: 'light-warning' },
  SHARE_TEMPLATE: { text: 'Share a template', avatar: 'AlignLeftIcon', color: 'light-primary' },
  USE_TEMPLATE: { text: 'Use a template', avatar: 'AlignLeftIcon', color: 'light-primary' },
  DELETE_TEMPLATE: { text: 'Delete a template', avatar: 'AlignLeftIcon', color: 'light-danger' },
  ADD_LEAD_SALESLOFT: { text: 'Add to Salesloft', avatar: 'MailIcon', color: 'light-success' },
  ASSIGN_CADENCE_SALESLOFT: { text: 'Add a SL Cadence', avatar: 'MailIcon', color: 'light-success' },
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      actionFeed: state => state.api.actionFeed
        .filter(action => !!actionToTitle[action.type])
        .map(action => ({
          avatar: actionToTitle[action.type].avatar,
          color: actionToTitle[action.type].color,
          ...action,
        })),
    }),
  },
  methods: {
    async refreshAction() {
      this.loading = true
      await this.$store.dispatch('api/loadFeed')
      await sleep(2000)
      this.loading = false
    },
  },
}
</script>
