<template>
  <b-card no-body>
    <b-table
      v-if="users.length>0"
      class="position-relative mb-0"
      :items="users"
      :fields="getTableHeader()"
      primary-key="id"
      responsive
      show-empty
      empty-text="No matching records found"
      hover
    >

      <template v-slot:head(paidUser)="data">
        <span v-html="data.field.label" />
        <feather-icon
          v-b-tooltip.hover.topright="'Assign your purchased license to a desired user'"
          style="margin-left: 8px;"
          variant="outline-primary"
          size="14"
          icon="InfoIcon"
          class="text-muted"
        />
      </template>

      <!-- Column: User -->
      <template #cell(user)="data">
        <b-media style="align-items:center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.pictureURL"
              :text="abbrev(data.item.name)"
              :variant="`light-${colorFromStatus(data.item.status)}`"
            />
          </template>
          <b>{{ data.item.name }}</b>
          <span v-if="data.item.salesEnablementTool">
            <b-img
              v-b-tooltip.hover.top="`Connected to ${data.item.salesEnablementTool.toLowerCase()}.`"
              :src="getSalesEnablementLogo(data.item.salesEnablementTool)"
              style="width: 0.65rem; margin-left: 0.35rem;"
            />
          </span>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(paidUser)="data">
        <b-form-checkbox
          v-model="data.item.isPremium"
          class="custom-control-primary checkbox-custom-border"
          :disabled="!account.isAdmin"
          @change="setPremium(data.item, data.index)"
        />
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          v-b-tooltip.hover.top="data.item.status === 'pending' ? 'This user must first download Surfe and connect to their CRM':''"
          pill
          :variant="`light-${colorFromStatus(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
          <feather-icon
            v-if="data.item.status === 'pending'"
            variant="outline-primary"
            size="14"
            icon="InfoIcon"
            class="text-muted"
          />
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          v-if="users.length>1"
          variant="link"
          left
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="!data.item.isAdmin"
            @click="showConfirmAdmin(data.item)"
          >
            <feather-icon
              icon="AwardIcon"
              class="text-success"
            />
            <span class="align-middle ml-2 text-info">Make admin {{ data.value }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="!data.item.hasCRMAccess"
            @click="resendInvite(data.item)"
          >
            <feather-icon icon="SendIcon" />
            <span class="align-middle ml-2">Resend invite</span>
          </b-dropdown-item>

          <b-dropdown-item
            :disabled="data.item.email == account.email"
            @click="showConfirmDelete(data.item)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-2">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- Column: Team -->
      <template #cell(team)="data">
        <b-form-group>
          <v-select
            v-model="data.item.team"
            append-to-body
            :clearable="data.item.team && !!data.item.team.name"
            :options="teams"
            label="name"
            @input="updateUserTeam(data.item)"
            @search="onSearch"
          >
            <div
              slot="no-options"
            >
              <span
                v-if="teamSearchQuery !== ''"
                class="team-search"
                @click="createTeam(data.item)"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Create team
              </span>
              <span v-if="teamSearchQuery === ''">
                Start typing to create a team
              </span>
            </div>
          </v-select>
        </b-form-group>
      </template>
    </b-table>

    <!-- Modal add/remove seats -->
    <b-modal
      id="modal-billing"
      ref="modal-billing"
      title="Add more licences"
      centered
      hide-footer
    >
      <p>
        All your <b class="text-primary">{{ account.licences }}</b> licences are currently assigned.<br>
        Add more licences to your account to upgrade this user.
      </p>

      <b-button
        style="float:right"
        variant="primary"
        to="/billing"
      >
        Go to Billing
      </b-button>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BTable,
  BMedia,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  VBTooltip,
  BModal,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { trackEvent } from '@core/utils/tracking'
import vSelect from 'vue-select'

import salesloftLogo from '@/assets/images/tools/salesloft.png'
import outreachLogo from '@/assets/images/tools/outreach.png'
import lemlistLogo from '@/assets/images/tools/lemlist.png'

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BTable,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BModal,
    BButton,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      teamSearchQuery: '',
      showBillingModal: false,
      salesEnablementLogo: '',
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      users: state => state.api.users,
      teams: state => state.api.teams?.map(x => ({ name: x.name, id: x.id, color: x.color })),
    }),
  },
  async mounted() {
    await this.$store.dispatch('api/loadTeams')
  },
  methods: {
    onSearch(searchQuery) {
      this.teamSearchQuery = searchQuery
    },
    getTableHeader() {
      const tableHeader = [
        {
          key: 'user',
          label: 'Surfe User',
          ...(this.account.isAdmin && { thStyle: { width: '250px' } }),
        },
        {
          key: 'email',
          label: 'Email',
          ...(this.account.isAdmin && { thStyle: { width: '150px' } }),
        },
        {
          key: 'paidUser',
          label: 'Paid',
          ...(this.account.isAdmin && { thStyle: { width: '100px' } }),
        },
        {
          key: 'status',
          label: 'Status',
          ...(this.account.isAdmin && { thStyle: { width: '100px' } }),
        },
      ]

      if (this.account.isAdmin) {
        tableHeader.push({ key: 'actions', label: 'Actions', thStyle: { width: '90px' } })
        tableHeader.push({ key: 'Team', label: 'Team', thStyle: { width: '250px' } })
      }

      return tableHeader
    },
    async createTeam() {
      const searchQuery = this.teamSearchQuery
      this.$bvModal
        .msgBoxConfirm('A new team will be created in your workspace.', {
          title: `Create team '${searchQuery}'`,
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (!value) {
            this.teamSearchQuery = ''
            return
          }

          try {
            await this.$store.dispatch('api/createTeam', {
              name: searchQuery,
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Team '${searchQuery}' created`,
                icon: 'UsersIcon',
                variant: 'success',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            throw error
          }
        })
    },
    async updateUserTeam(user) {
      try {
        await this.$store.dispatch('api/updateUser', {
          id: user.id,
          value: { teamId: user.team?.id ?? '' },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Team Updated',
            icon: 'UsersIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
    async setPremium(user, i) {
      const nPremium = this.users.filter(u => u.isPremium).length
      if (user.isPremium && nPremium > this.account.licences) {
        this.$refs['modal-billing'].show()
        this.users[i].isPremium = false
        return
      }

      // There's room for premium
      try {
        await this.$store.dispatch('api/updateUser', {
          id: user.id,
          value: { isPremium: user.isPremium },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Premium status updated',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
    showConfirmAdmin(user) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to make ${user.name} an admin.`, {
          title: 'Make admin',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Make admin',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (!value) return
          try {
            await this.$store.dispatch('api/updateUser', {
              id: user.id,
              value: { isAdmin: true },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Admin status updated',
                icon: 'SendIcon',
                variant: 'success',
              },
            })
            await trackEvent({ type: 'dashboard-team-made_admin', props: { email: user.email } })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            throw error
          }
        })
    },
    async resendInvite(user) {
      try {
        await this.$store.dispatch('api/inviteUsers', [{
          name: user.name,
          email: user.email,
          isAdmin: user.isAdmin,
        }])
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation sent successfully',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-team-invite_resent', props: { email: user.email } })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
    showConfirmDelete(user) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to remove ${user.name} from your organization.`, {
          title: 'Delete user',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Keep',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (!value) return
          await trackEvent({ type: 'dashboard-team-deleted', props: { email: user.email } })
          await this.$store.dispatch('api/deleteUser', user.id)
        })
    },
    colorFromStatus(status) {
      switch (status) {
        case 'pending':
          return 'warning'
        case 'admin':
          return 'info'
        case 'active':
          return 'success'
        default:
          return 'secondary'
      }
    },
    abbrev(name) {
      return name.split(' ').map(n => n[0]).join('')
    },
    getSalesEnablementLogo(tool) {
      switch (tool.toLowerCase()) {
        case 'salesloft':
          return salesloftLogo
        case 'outreach':
          return outreachLogo
        case 'lemlist':
          return lemlistLogo
        default:
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .team-search {
    cursor: pointer;
  }
</style>
<style>
 .checkbox-custom-border .custom-control-label::before {
  border: 1px solid #073742;
 }
</style>
