var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-8"},[(_vm.workspaceId)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"success","href":("https://docs.google.com/spreadsheets/d/" + _vm.workspaceId),"target":"_blank"}},[_vm._v(" Access CRM Spreadsheet ")]):_vm._e(),(_vm.workspaceId)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"","href":"/settings?activeTab=danger-zone"}},[_vm._v(" Having Problems? ")]):_vm._e()],1),(_vm.paymentIntentError)?_c('note',{staticClass:"p-2",attrs:{"info":{
      message: 'The payment of your last invoice did not succeed, please update your payment method.',
      href:'/billing',
      hrefMessage:'Check billing.'
    }}}):_vm._e(),(!_vm.paymentIntentError && _vm.cardWillExpire)?_c('note',{attrs:{"info":{
      message: 'Your credit card will expire in less then 3 months!',
      href:'/billing',
      hrefMessage:'Check billing.'
    }}}):_vm._e(),(!_vm.account.isSubscribed && _vm.account.settings.assumeEveryoneIsAdminWhenNoSubscription)?_c('note',{attrs:{"variant":"info","info":{
      message: 'All members of the team currently have admin status. Admins can pay and configure settings for the team. Once one member pays, they become admin and others become normal users. For more information please contact our',
      href:'https://intercom.help/surfe/en/',
      hrefMessage:'Customer Support'
    }}}):_vm._e(),_c('invite-member',{staticClass:"mb-8"}),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"lg":"8","sm":"12"}},[_c('user-list'),_c('owners-list')],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('action-feed')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }