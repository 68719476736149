<template>
  <div>
    <b-button
      v-b-modal.modal-invite
      class="my-6"
      variant="primary"
      style="display:flex;align-items:center"
    >
      <feather-icon
        icon="PlusIcon"
        size="20"
        class="mr-2"
      />
      <span class="align-middle">Invite Team Member</span>
    </b-button>

    <InviteMemberModal />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import InviteMemberModal from '@/views/team/components/InviteMemberModal.vue'

export default {
  components: {
    InviteMemberModal,
    BButton,
  },
}
</script>
