<template>
  <b-alert
    show
    :variant="variant"
    class="py-2"
  >
    <p class="mx-2">
      {{ info.message }}
      <b-link
        :href="info.href"
      >
        {{ info.hrefMessage }}
      </b-link>
    </p>
  </b-alert>
</template>

<script>
import { BAlert, BLink } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BLink,
  },
  props: {
    info: {
      required: true,
      type: Object,
      default: () => {},
    },
    variant: {
      required: false,
      type: String,
      default: 'danger',
    },
  },
}
</script>
